:root {
  --ion-color-secondary-dark: #831804;
  --ion-color-secondary-light: #dd9182;
  --ion-color-secondary-light-rgb: 221, 145, 130;
  --ion-color-primary-dark: #074946;
  --ion-color-primary-light: #b7dedd;
  --ion-color-primary-light-rgb: 183, 222, 221;
  --ion-color-primary-lighter: #f1f8f8;
  --ion-color-tertiary-dark: #006084;
  --ion-color-tertiary-light: #80d0ee;
  --ion-color-tertiary-light-rgb: 128, 208, 238;
  --ion-color-primary-header: #56b2af;
  --ion-color-secondary-header: #c94e37;
  --ion-color-danger-background: #ffdadb;
  --ion-color-primary-d1: #0b7470;
  --ion-color-primary-d2: #0a6662;
  --ion-color-primary-d3: #085754;
  --ion-color-primary-d4: #074946;
  --ion-color-primary-d5: #063a38;
  --ion-color-primary-d6: #042b2a;
  --ion-color-primary-l1: #3ea7a3;
  --ion-color-primary-l2: #56b2af;
  --ion-color-primary-l3: #6ebdba;
  --ion-color-primary-l4: #87c8c6;
  --ion-color-primary-l5: #9fd3d1;
  --ion-color-primary-l6: #b7dedd;
  --ion-color-dark: black;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-yellow-rgb: 255, 253, 208;
  --ion-color-blue-rgb: 218, 240, 255;
  --ion-color-red-rgb: 246,223,223;
  --ion-color-yellow: #FFFCC9;
  
  --ion-color-green:#3cb44b;
  --ion-color-navblue:#4363d8; 
  --ion-color-orange:#be7038; 
  --ion-color-purple:#911eb4; 
  --ion-color-pink:#f032e6; 
  --ion-color-brown:#9a6324; 
  --ion-color-maroon:#800000; 
  
  --ion-color-green-rgb: 60, 180, 75;
  --ion-color-navblue-rgb: 67, 99, 216; 
  --ion-color-orange-rgb:190, 112, 56; 
  --ion-color-purple-rgb:145, 30, 180; 
  --ion-color-pink-rgb:240, 50, 230; 
  --ion-color-brown-rgb:154, 99, 36; 
  --ion-color-maroon-rgb:128, 0, 0; 

  /* --ion-toolbar-background: linear-gradient(to bottom, var(--ion-color-primary-lighter), var(--ion-color-primary-light)); */
  --ion-toolbar-background: var(--ion-color-light);
  --ion-toolbar-color: var(--ion-color-primary-dark);
  background-color: var(--ion-color-light);
  
  
  --ion-color-progress-20-0: #ff0000;
  --ion-color-progress-40-20: #ff5a00;
  --ion-color-progress-60-40: #ff9a00;
  --ion-color-progress-80-60: #c6dd0e;
  --ion-color-progress-100-80: #8abf14;

  --ion-color-progress-20-0-rgb: 255, 0, 0;
  --ion-color-progress-40-20-rgb: 255, 90, 0;
  --ion-color-progress-60-40-rgb: 255, 154, 0;
  --ion-color-progress-80-60-rgb: 198, 221, 14;
  --ion-color-progress-100-80-rgb: 138, 191, 20;

	--ion-color-wip: #f7e7b3;
	--ion-color-complete: #dafcd7;
	--ion-color-complete-rgb: 218, 252, 215;
	--ion-color-wip-rgb: 247, 231, 179;

  --ion-color-img-border: #ff5a00;


  --ion-color-orthogal-yellow-rgb: 255, 241, 0;
  --ion-color-orthogal-orange-rgb: 255, 140, 0;
  --ion-color-orthogal-red-rgb: 232, 17, 35;
  --ion-color-orthogal-magenta-rgb: 236, 0, 140;
  --ion-color-orthogal-purple-rgb: 145, 30, 180;
  --ion-color-orthogal-blue-rgb: 0, 24, 143;
  --ion-color-orthogal-cyan-rgb: 0, 188, 242;
  --ion-color-orthogal-green-rgb: 0, 158, 73;
  --ion-color-orthogal-lime-rgb: 186, 216, 10;

  --ion-color-hyperlink: #0000ff;


  --ion-color-bhp-blue: #21d0dd;
  --ion-color-bhp-red: #dd2121;
  --ion-color-bhp-red-rgb: 221, 33, 33;
  --ion-color-bhp-blue-rgb: 33, 208, 221;
  --ion-color-bhp-navy-blue: #0d8dd9;
  --ion-color-bhp-font-blue: #1d67a3;
  --ion-color-bhp-gray: #65676b;
  --ion-color-bhp-vypzee-dark: #538629;
  --ion-color-bhp-vypzee-medium: #66a830;
  --ion-color-bhp-vypzee-light: #81cc35;
  --ion-color-bhp-lightgray: #e7e9ec;
  --ion-color-bhp-yellow: #ffde59;
  --ion-color-bhp-yellow-rgb: 255, 222, 89;
  --ion-color-bhp-hdr-dark: #0C0602;
  --ion-color-bhp-hdr-dark-rgb: 12, 6, 2;
  --ion-color-bhp-hdr-yellow: #e1d0ae;
  --ion-color-bhp-hdr-yellow-dark: #926c0b;
  --ion-color-bhp-hdr-yellow-rgb: 225, 208, 174;
  --ion-color-bhp-hdr-yellow-dark-rgb: 146, 108, 11;
  --ion-color-bhp-white: #ffffff;
  --ion-color-bhp-white-rgb: 255, 255, 255;

  /* --ion-color-bhp-footer: #333;
  --ion-color-bhp-base: #e0e0e0; */
  --ion-color-bhp-footer: #1e1e1e;
  --ion-color-bhp-footer-rgb: 30, 30, 30;
  --ion-color-bhp-base: #e0e0e0;
  --ion-color-bhp-button-glow-1: #6f00ff;
  --ion-color-bhp-button-glow-2: #00e1ff;
  --ion-color-bhp-button-glow-3: #ff0000;
  --ion-color-bhp-button-glow-4: #1eff00;
  --ion-color-bhp-font-color: #707072; 
  --ion-color-bhp-font-color-dark: #0e0e0e;
}
